@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Phonk';
  src: url('./fonts/PhonkRegular.otf'); /* IE9 Compat Modes */
  src: url('./fonts/PhonkRegular.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/PhonkRegular.otf') format('otf'), /* Super Modern Browsers */
       url('./fonts/PhonkRegular.otf') format('otf'), /* Pretty Modern Browsers */
       url('./fonts/PhonkRegular.otf')  format('otf'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'PlayB';
  src: url("./fonts/Play-Bold.ttf") format('ttf');
  src: url('./fonts/Play-Bold.ttf'); /* IE9 Compat Modes */
  src: url('./fonts/Play-Bold.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/Play-Bold.ttf') format('ttf'), /* Super Modern Browsers */
  url('./fonts/Play-Bold.ttf') format('ttf'), /* Pretty Modern Browsers */
  url('./fonts/Play-Bold.ttf')  format('ttf'), /* Safari, Android, iOS */
 }

 @font-face {
  font-family: 'PlayR';
  src: url("./fonts/Play-Regular.ttf") format('ttf');
  src: url('./fonts/Play-Regular.ttf'); /* IE9 Compat Modes */
  src: url('./fonts/Play-Regular.ttf') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/Play-Regular.ttf') format('ttf'), /* Super Modern Browsers */
  url('./fonts/Play-Regular.ttf') format('ttf'), /* Pretty Modern Browsers */
  url('./fonts/Play-Regular.ttf')  format('ttf'), /* Safari, Android, iOS */
 }

.snapclass{
overflow-y:hidden;
scroll-snap-type: x;
scroll-snap-type: x mandatory;
scroll-snap-type: x proximity;
scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


